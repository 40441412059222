import { buildMenuItems } from 'helpers/menu_item_builder'
import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

import { JSLogo } from 'icons/JSLogo'
import { JungleScoutLogo } from 'icons/JungleScoutLogo/JungleScoutLogoV2'

import { MobileMenuItem } from './MobileMenuItem'
import { MobileAmazonAccountDropdown } from './MobileAmazonAccountDropdown'

export const TRANSITION_TIMING = 'cubic-bezier(0.25, 0.1, 0.25, 1)'

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  height: 48px;
  min-height: 48px;
  background-color: ${props =>
    props.collapsed ? props.theme.colors.primary : 'black'};
  transition: background-color 0.3s ${TRANSITION_TIMING};

  &:hover {
    cursor: ${props => (props.disabled ? '' : 'pointer')};
  }
`

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '20px',
    height: '16px',
    left: '20px',
    top: '16px',
    zIndex: 100000
  },
  bmBurgerBars: {
    background: COLORS.grey900
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    zIndex: 100000
  },
  bmMenu: {
    background: COLORS.grey900,
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: COLORS.grey900
  },
  bmItemList: {
    color: '#b8b7ad'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const navigateHome = () => {
  window.location.hash = '/dashboard'
}

export const MobileMenu = ({
  ignoreToggleState,
  expandedOption,
  admin,
  flagData,
  membershipInfo,
  expandSidebarOption,
  currentPath,
  user,
  user: { permissions, created_at },
  theme,
  enableWrapping
}) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const globalData = useSelector(state => state.globalData)

  const buildMenuItemsProps = {
    t,
    created_at,
    flagData,
    hasActiveMembership: membershipInfo.hasActiveMembership,
    featureLimits: globalData?.featureLimits
  }

  const getLogo = () => {
    const jsLogoProps = {
      opacity: 0,
      width: '0px',
      height: '0px'
    }

    const jungleScoutLogoProps = {
      opacity: 1,
      height: '20px',
      width: '131px'
    }

    return (
      <>
        <JSLogo {...jsLogoProps} />
        <JungleScoutLogo {...jungleScoutLogoProps} />
      </>
    )
  }

  const menuItems = buildMenuItems(buildMenuItemsProps)
  return (
    <Menu
      styles={styles}
      customCrossIcon={false}
      onOpen={() => {
        setMenuOpen(true)
      }}
      onClose={() => {
        setMenuOpen(false)
      }}
      isOpen={menuOpen}>
      <>
        <LogoWrapper
          collapsed={false}
          theme={theme}
          disabled={false}
          onClick={navigateHome}>
          {getLogo()}
        </LogoWrapper>
        <MobileAmazonAccountDropdown />
        {menuItems.map((item, key) => {
          return (
            <MobileMenuItem
              key={`menu${key}`}
              item={item}
              collapsed={false}
              ignoreToggleState={ignoreToggleState}
              expandedOption={expandedOption}
              setMenuOpen={setMenuOpen}
              admin={admin}
              flagData={flagData}
              membershipInfo={membershipInfo}
              currentPath={currentPath}
              expandSidebarOption={expandSidebarOption}
              enableWrapping={enableWrapping}
              permissions={permissions}
              user={user}
            />
          )
        })}
      </>
    </Menu>
  )
}
